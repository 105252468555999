application.register("comp_references_single_bookolo_boost", class extends Stimulus.Controller {
    connect() {
        const self = this;

        this._cards = self.targets.findAll('card')

        gsap.registerPlugin(ScrollTrigger);

        ScrollTrigger.matchMedia({
            '(min-width: 60em)': () => {
                const timeline = gsap.timeline({
                    scrollTrigger: {
                        trigger: self.targets.find('scene'),
                        start: 'top-=200 top',
                        end: `+${100 * self._cards.length}%`,
                        scrub: true,
                        pin: true,
                        markers: false
                    }
                })

                self._cards.forEach((card) => {
                    ScrollTrigger.saveStyles(card)
                })

                timeline.fromTo(self._cards[0],
                    {
                        translateY: 0,
                        scale: 1,
                    },
                    {
                        translateY: '-21%',
                        scale: 0.8
                    }
                )

                timeline.fromTo(self._cards[1],
                    {
                        translateY: '100vh',
                        scale: 1,
                    },
                    {
                        translateY: '-5.5%',
                        scale: 0.9
                    },
                    '>-85%'
                )

                timeline.fromTo(self._cards[2],
                    {
                        translateY: '100vh'
                    },
                    {
                        translateY: '11%'
                    },
                    '>-25%'
                )
            }
        })
    }
})
